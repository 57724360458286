<template>
    <div>
    <h1>Tupp Type</h1>
    <p>{{ tjo_oman }}</p> <!--Skriver ut variablen som användarn ska skriva av.-->
    <v-text-field label="Skriv av texten ovan:" :disabled="is_correct" :error="show_error" v-model="text_input"></v-text-field>
    <!--Ett textblock med flera olika egenskaper. disabled är en boolesk funktion som stoppar användaren från att skriva om den uppfylls.
    error är en boolesk funktion som gör textblocket till rött om användaren skriver fel. v-model är en prop som syncar variabeln som användaren
    skriver in med textrutan. -->

    <p v-if="is_correct">Grattis, du klarade det! <!--Paragraf som endast visas om is_correct = true.-->
    <br>
    Det tog {{ end_time.toFixed(2) }} sekunder. <!--//Skriver ut tiden det tog med två decimaler.-->
    <v-text-field label="Skriv ditt användarnamn" v-model="username"></v-text-field>
    <v-btn @click="sendRes">Skicka resultat</v-btn>

</p>
</div>
</template>

<script>
import texts from '@/assets/tuppTypeText.json'
import axios from 'axios'

export default {
  data: () => ({
    tjo_oman: texts[Math.floor(Math.random() * 8)], // Dataobjekt med variabler.
    show_error: false,
    text_input: '',
    username: '',
    is_correct: false,
    start_time: null,
    end_time: null
  }),
  watch: {
    text_input (newTextInput) { // Funktion som returnerar text_input värde
      if (this.start_time == null) { // Startar en tidräkning endast om användaren börjar skriva.
        this.start_time = new Date()
      }
      const inputLength = newTextInput.length
      const correct = this.tjo_oman.substring(0, inputLength) /* En variabel correct som innehåller lika många tecken i den
            korrekta meningnen som tecken som har skrivits in i textblocket. */
      this.show_error = (newTextInput !== correct) /* Om textblocket inte är likadant som correct så kommer error funktionen
            sättas igång. */
      this.is_correct = (newTextInput === this.tjo_oman) /* Gör is_Correct till true om new_text_input är likadant som den
            rätta meningnen. */
      if (this.is_correct) { // Om användaren skrivit rätt så slutar tidräkningen och den konverteras till sekunder.
        this.end_time = (new Date() - this.start_time) / 1000
      }
    }
  },
  methods: {
    sendRes () {
      axios.get(`/api/futftype/add?name=${this.username}&tid=${this.end_time}`)
      this.$router.push('/tupptype_leaderboard')
    }
  }
}
</script>
